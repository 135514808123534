import { ApolloClient, gql, isApolloError, NormalizedCacheObject, QueryOptions } from '@apollo/client'
import { getCookie } from 'cookies-next'
import { ServerResponse } from 'http'
import { AUTH_JWT_COOKIE } from '@/constants/cookies'
import type { User } from '@/services/UserService'
import { GET_USER_PROFILE_QUERY } from '@/services/UserService/queries'
import { GuildPermission, GuildPlan, UserProfileQuery } from '@/types/codegen-federation'
import { decodeAccessToken } from '../auth-utils'
import { reportErrorToBugsnag } from '../bugsnag'
import { logger } from '../logging'
import { getStringOrEmptyString, getValidString } from '../primitives/strings'
import { ServerRequest } from '../types'

export function getAccessTokenFromServer(req: ServerRequest, res?: ServerResponse): string {
  return getStringOrEmptyString(getValidString(getCookie(AUTH_JWT_COOKIE, { req, res })))
}

export function getUserUuidFromJwt(req: ServerRequest, res?: ServerResponse): string | undefined {
  const accessToken = getAccessTokenFromServer(req, res)
  return decodeAccessToken(accessToken)?.userId
}

export function getUserIdAndAccessToken(
  req: ServerRequest,
  res?: ServerResponse,
): { userId?: string; accessToken?: string } {
  const accessToken = getValidString(getCookie(AUTH_JWT_COOKIE, { req, res }))
  if (!accessToken) return {}
  const decoded = decodeAccessToken(accessToken)
  return { userId: decoded?.userId, accessToken }
}

export async function getUser(client: ApolloClient<object>, options?: QueryOptions): Promise<User | null> {
  try {
    const result = await client.query<UserProfileQuery>({
      query: GET_USER_PROFILE_QUERY,
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
      ...options,
    })
    if (result.errors?.length) {
      logger().error('Failed to fetch user profile', { errors: result.errors })
      return null
    }
    return result.data?.user ?? null
  } catch (e) {
    const err = e as Error
    if (isApolloError(err)) {
      if (err.graphQLErrors.length && err.graphQLErrors.some((e) => e.message === 'Not Authorized')) {
        logger().warn(`User not authorized. Auth token is either missing or invalid`, { err })
        return null
      }
    }
    logger().error('Failed to fetch user profile', { err })
    return null
  }
}

export async function getUserGuildStatus(uuid: string | null): Promise<GuildStatus | null> {
  if (!uuid) return null

  if (typeof window !== 'undefined') {
    throw new Error('getUserGuildStatus should only be called on the server!')
  }

  if (!process.env.GUILD_NFT_URL || !process.env.GUILD_NFT_API_KEY) {
    throw new Error('Missing GUILD_NFT_URL or GUILD_NFT_API_KEY environment variables!')
  }

  const baseUrl = `${process.env.GUILD_NFT_URL}/guildUser/${uuid}`

  try {
    const response = await fetch(baseUrl, {
      method: 'GET',
      headers: {
        Authorization: process.env.GUILD_NFT_API_KEY,
      },
    })

    if (!response.ok) {
      return null
    }

    const data: GuildStatus = await response.json()
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    reportErrorToBugsnag(`Failed to fetch guild user status! ${error}`)
    return null
  }
}

export interface GuildStatus {
  uuid: string
  isGuildMember: boolean
  guildMemberStart: Date | null
  guildMemberEnd: Date | null
  guildMemberReason: string
  guildPermissions: GuildPermission[]
  guildPlan: GuildPlan
  guildRoles: {
    canVote: boolean
    hasEarlyAccess: boolean
    hasFreeTickets: boolean
    hasMerchDiscount: boolean
    hasChosenTickets: boolean
    hasFirstVote: boolean
    kycVerified: boolean
    kycFailed: boolean
  }
}

export const GET_USER_PUBLIC_PROFILE = gql`
  query GetUser($uuid: ID!, $fallbackToInitials: Boolean) {
    publicUserProfile(uuid: $uuid) {
      firstName
      lastName
      image(fallbackToInitials: $fallbackToInitials)
      id
      city
      state
      country
    }
  }
`

export const getUserPublicProfile = async (
  client: ApolloClient<NormalizedCacheObject>,
  fallbackToInitials = true,
  uuid?: string,
) => {
  const { data } = await client.query({
    query: GET_USER_PUBLIC_PROFILE,
    variables: { uuid, fallbackToInitials },
    errorPolicy: 'all',
  })
  return data.publicUserProfile
}

export interface UserPublicProfile {
  firstName: string
  lastName: string
  image: string
  id: string
  city?: string
  state?: string
  country?: string
}
