import { useCallback, useMemo } from 'react'
import type { NextRouter, PrefetchOptions, Url } from 'next/dist/shared/lib/router/router'
import { useRouter } from 'next/router'
import { createDebouncedFunction, DebounceOptions } from '../function-utils'
import { logger } from '../logging'

/** Copied from next.js types. This interface is not publicly exported. */
export interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

type PushFn = (url: Url, as?: Url, options?: TransitionOptions) => Promise<boolean>
type DebouncedPushFn = (url: Url, as?: Url, options?: TransitionOptions) => Promise<boolean> | undefined

export function useSafeRouter(): NextRouter {
  const router = useRouter()

  const safePush = useCallback<PushFn>(
    (url, as, options) => {
      return router
        .push(url, as, options)
        .then((success) => {
          logger().debug('router push succeeded', { url, as, options })
          return success
        })
        .catch((err) => {
          logger().error('router push failed', { url, as, options }, err)
          return false
        })
    },
    [router],
  )

  const safeReplace = useCallback<PushFn>(
    (url, as, options) => {
      return router
        .replace(url, as, options)
        .then((success) => {
          logger().debug('router replace succeeded', { url, as, options })
          return success
        })
        .catch((err) => {
          logger().error('router replace failed', { url, as, options }, err)
          return false
        })
    },
    [router],
  )

  const safePrefetch = useCallback(
    (url: string, asPath?: string, options?: PrefetchOptions): Promise<void> => {
      return router
        .prefetch(url, asPath, options)
        .then(() => {
          logger().debug('router prefetch succeeded', { url, asPath, options })
        })
        .catch((err) => {
          logger().error('router prefetch failed', { url, asPath, options }, err)
        })
    },
    [router],
  )

  return useMemo(() => {
    return {
      ...router,
      prefetch: safePrefetch,
      push: safePush,
      replace: safeReplace,
    }
  }, [router, safePrefetch, safePush, safeReplace])
}

export function useSafeShallowPush(opts?: DebounceOptions) {
  const { push } = useSafeRouter()

  const debouncedShallowPush = useMemo(() => {
    return createDebouncedFunction<PushFn>((url, as, options) => push(url, as, { ...options, shallow: true }), opts)
  }, [push, opts])

  const shallowPush = useCallback<DebouncedPushFn>(
    (url, as, options) => debouncedShallowPush(url, as, options),
    [debouncedShallowPush],
  )

  return useMemo(() => ({ shallowPush }), [shallowPush])
}
