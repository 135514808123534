import { useCallback, useMemo } from 'react'
import { logger } from '@/utils/logging'

interface MobileShareProps {
  url: string
  title?: string
  text?: string
  onShareFail?: () => void
}

export const useNativeMobileShare = () => {
  const share = useCallback(async ({ url, title, text, onShareFail }: MobileShareProps) => {
    const data = {
      title,
      text,
      url,
    }

    if (!navigator.share || !navigator.canShare(data)) return onShareFail?.()

    try {
      await navigator.share(data)
    } catch (error: unknown) {
      if (error instanceof DOMException) {
        switch (error.name) {
          case 'AbortError':
          case 'InvalidStateError':
            return
          case 'NotSupportedError':
          case 'NotAllowedError':
            onShareFail?.()
            break
          case 'DataError':
            onShareFail?.()
            logger().error(`Share failed with ${error.name}:`, { error, data }, error)
            break
          default:
            onShareFail?.()
            logger().error(`Share failed Unexpected share error ${error.name}:`, { error, data }, error)
        }
      } else {
        onShareFail?.()
        logger().error(`Share failed Non-DOMException share error:`, { error, data }, error)
      }
    }
  }, [])

  return useMemo(() => {
    return { share }
  }, [share])
}
