import { useCallback, useMemo } from 'react'
import { getCookie } from 'cookies-next'
import { OptionsType } from 'cookies-next/lib/types'
import jwtDecode from 'jwt-decode'
import { useRouter } from 'next/router'
import { AUTH_JWT_COOKIE, AUTH_SESSION_COOKIE, AUTH_SESSION_COOKIE_0 } from '@/constants/cookies'
import { buildLoginUrl, buildLogoutUrl } from './BuildLoginUrl'
import { buildSignupUrl } from './BuildSignupUrl'
import { useSafeTrack } from './analytics'
import { getStringOrEmptyString, getValidString } from './primitives/strings'
import { Maybe } from './types'

export { buildLoginUrl, buildLogoutUrl } from './BuildLoginUrl'
export { buildSignupUrl } from './BuildSignupUrl'

const resolveReturnPath = (asPath: string, returnTo?: string) => {
  if (returnTo) return returnTo
  if (typeof window !== 'undefined') return window.location.href
  return asPath
}

export function useLoginUrl(returnTo?: string): {
  loginUrl: string
  trackLoginStarted: (componentName: string) => void
} {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  const returnToPath = resolveReturnPath(asPath, returnTo)

  const loginUrl = buildLoginUrl(returnToPath)
  const trackLoginStarted = useCallback(
    (componentName: string) =>
      track('Log In Started', { started_from: componentName, path: asPath, returnTo: returnToPath }),
    [returnToPath, track, asPath],
  )

  return useMemo(() => {
    return { loginUrl, trackLoginStarted }
  }, [loginUrl, trackLoginStarted])
}

export function useSignupUrl(returnTo?: string) {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  const returnToPath = resolveReturnPath(asPath, returnTo)

  const signupUrl = buildSignupUrl(returnToPath)
  const trackSignupStarted = useCallback(
    (componentName: string) =>
      track('Sign Up Started', { started_from: componentName, path: asPath, returnTo: returnToPath }),
    [returnToPath, track, asPath],
  )

  return useMemo(() => {
    return { signupUrl, trackSignupStarted }
  }, [signupUrl, trackSignupStarted])
}

export function useTrackLoginStarted() {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  return useCallback(
    (componentName: string, returnTo?: string) => {
      const returnToPath = returnTo ?? asPath
      track('Log In Started', { started_from: componentName, path: asPath, returnTo: returnToPath })
    },
    [track, asPath],
  )
}

export function useTrackSignupStarted() {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  return useCallback(
    (componentName: string, returnTo?: string) => {
      const returnToPath = returnTo ?? asPath
      track('Sign Up Started', { started_from: componentName, path: asPath, returnTo: returnToPath })
    },
    [track, asPath],
  )
}

export function useLogoutUrl(returnTo?: string) {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  const returnToPath = resolveReturnPath(asPath, returnTo)
  const logoutUrl = buildLogoutUrl(returnToPath)

  const trackLogoutStarted = useCallback(
    (componentName: string) =>
      track('Log Out Started', { started_from: componentName, path: asPath, returnTo: returnToPath }),
    [track, asPath, returnToPath],
  )

  return useMemo(() => ({ logoutUrl, trackLogoutStarted }), [logoutUrl, trackLogoutStarted])
}

interface DecodedJwt {
  /** The intended audience of the JWT. This value is currently always "ellis_island" */
  aud: 'ellis_island'

  /** The issuer of the JWT. Currently always "ellis_island" */
  iss: 'ellis_island'

  /** The receiver of the JWT. In our case, this value is always "angel_web" */
  azp: 'angel_web'

  /** JWT issued at date in seconds since the epoch. Multiply by 1000 to get ms when creating a Date object. */
  iat: number

  /** JWT expiration date in seconds since the epoch. Multiply by 1000 to get ms when creating a Date object. */
  exp: number

  jti: string

  nbf: number

  /** space delimited list of scopes allowed for this JWT. For example "openid profile email" */
  scope: string

  /** User id */
  sub: string

  /** subject type of the JWT. Currently always "user" */
  subject_type: 'user'

  /** JWT type. Currently always "access" */
  typ: 'access'
}

interface DecodedAccessToken extends DecodedJwt {
  userId: string
}

export function getAccessTokenFromClient(): string {
  return getStringOrEmptyString(getValidString(getCookie(AUTH_JWT_COOKIE)))
}

export function getJwtFromCookie(): DecodedAccessToken | null {
  const accessToken = getAccessTokenFromClient()
  return decodeAccessToken(accessToken)
}

export function decodeAccessToken(accessToken: Maybe<string>): DecodedAccessToken | null {
  if (!accessToken) return null
  const decoded = accessToken ? jwtDecode<DecodedJwt>(accessToken) : null
  return decoded ? { ...decoded, userId: decoded.sub } : null
}

/**
 * This cookie contains the user's access token. Presence of this cookie indicates that the user is logged in.
 * @returns
 */
export function hasAuthJwtCookie(): boolean {
  return Boolean(getAccessTokenFromClient())
}

/**
 * The Auth Session Cookie is used to refetch the access token. This cookie should **NOT** be used to check if the user is logged in.
 * @param options
 * @returns
 */
export function hasAuthSessionCookie(options?: OptionsType): boolean {
  return Boolean(getCookie(AUTH_SESSION_COOKIE, options)) || Boolean(getCookie(AUTH_SESSION_COOKIE_0, options))
}
