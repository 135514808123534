/**
 * This should never be included on the frontend as it dramatically increases bundle size.
 */
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { IncomingMessage, ServerResponse } from 'http'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { hydraLocaleLink } from '@/services/ApolloClient/ApolloClient'
import {
  AdditionalHeaders,
  BaseClientConstructor,
  FEDERATION_CLIENT_NAME,
  FEDERATION_CLIENT_VERSION,
} from '@/services/ApolloClient/utils'
import { getRID } from '@/utils/RIDUtil'
import { getAccessTokenFromServer } from '@/utils/users/usersServer'
import { logErrorLink } from '../error-link'
import generatedPossibleTypes from '../generated-possibleTypes.json'

interface ServerSideRequest {
  req: IncomingMessage & {
    cookies: NextApiRequestCookies
  }
  res: ServerResponse
  locale?: string
  region?: string
  accessToken?: string
}

interface ServerClientConstructor extends BaseClientConstructor, ServerSideRequest {}

const serverAuthLink = ({ req, res, accessToken }: ServerSideRequest) =>
  setContext((_, { headers }) => {
    const token = accessToken || getAccessTokenFromServer(req, res)
    const additionalHeaders: AdditionalHeaders = {}
    const userIp = getUserIp(req)

    if (token) {
      additionalHeaders.Authorization = token
    }

    if (userIp) {
      additionalHeaders['X-FORWARDED-FOR'] = userIp
    }

    additionalHeaders['X-RID'] = getRID(req, res) as string
    additionalHeaders['X-PLATFORM'] = FEDERATION_CLIENT_NAME
    return {
      headers: {
        ...headers,
        ...additionalHeaders,
        'X-ANGEL-APP-NAME': FEDERATION_CLIENT_NAME,
        'X-ANGEL-APP-VERSION': FEDERATION_CLIENT_VERSION,
      },
    }
  })

function getUserIp(
  req: IncomingMessage & {
    cookies: NextApiRequestCookies
  },
) {
  const forwarded = req?.headers?.['x-forwarded-for']
  return typeof forwarded === 'string' ? forwarded.split(/, /)[0] : req?.socket?.remoteAddress || null
}

const createServerSideClient = ({
  accessToken,
  clientName,
  baseUrl,
  name,
  version,
  locale,
  region,
  req,
  res,
}: ServerClientConstructor) => {
  const backend = createHttpLink({ uri: `${baseUrl}/graphql` })

  return new ApolloClient({
    ssrMode: true,
    link: ApolloLink.from([
      logErrorLink(clientName),
      serverAuthLink({ req, res, accessToken }),
      hydraLocaleLink(region, locale),
      backend,
    ]),
    cache: new InMemoryCache({
      possibleTypes: generatedPossibleTypes,
    }),
    name,
    version,
  })
}

export const getServerSideClient = ({ req, res, locale, region, accessToken }: ServerSideRequest) => {
  return createServerSideClient({
    clientName: 'server-side-federation',
    baseUrl: process.env.NEXT_PUBLIC_HYDRA as string,
    name: FEDERATION_CLIENT_NAME,
    version: FEDERATION_CLIENT_VERSION,
    accessToken,
    locale,
    region,
    req,
    res,
  })
}
