import { useMemo } from 'react'
import { ApolloClient, useQuery } from '@apollo/client'
import {
  DiscountsListGuildCodesQuery,
  DiscountsListGuildCodesQueryVariables,
  GetGuildUserTicketsAvailableForSlugQuery,
  GuildEventActionType,
  GuildPlan,
  GuildRoles,
  GuildRolesQuery,
  TheatricalDiscount,
  UserGuildRolesAndPermissionsQuery,
} from '@/types/codegen-federation'
import { hasAuthJwtCookie } from '@/utils/auth-utils'
import { isProductionEnvironment } from '@/utils/environment-utils'
import {
  GET_USER_GUILD_ROLES,
  GET_USER_GUILD_TICKETS_AVAILABLE,
  GET_USER_GUILD_TICKETS_DISCOUNT_CODES,
  USER_GUILD_MEMBERSHIP,
  USER_GUILD_ROLES_AND_PERMISSSIONS,
} from '../UserService/queries'

export type GuildMember = {
  isGuildMember: boolean
  roles: (string | null)[]
  guildPermissions: GuildPermissions
  guildMemberReason: GuildEventActionType | null
  isSubscribedToGuild: boolean
  /**
   * Some perks are given to users based on them paying it forward, such as Early Access or Voting
   */
  isSubscribedToPifOrGuild: boolean
  /**
   * Indicates if the user has an active Guild membership because they are a paying Guild Member
   * At the time of this documentation, this means they are on the $20/month or $175/year plan
   * 20% discount on merch and free tickets to theatrical events are exclusive to this role
   */
  hasGuildMembership: boolean
  email?: string | null
  loading?: boolean
}

export type GuildPermissions = {
  hasFreeTickets: boolean
  hasMerchDiscount: boolean
  hasEarlyAccess: boolean
  canVote: boolean
  adFree: boolean
  publicViewingRight: boolean
  limitedAds: boolean
}

// TODO: The new API does not return guildPlan or guildMemberStart at this time.  Unfortunately checking user subscriptions does not work either.  We'll need to have the query modified to return those two.
export const useGuildMember = () => {
  const { data, loading, refetch, startPolling, stopPolling } = useQuery<UserGuildRolesAndPermissionsQuery>(
    USER_GUILD_ROLES_AND_PERMISSSIONS,
    {
      skip: !hasAuthJwtCookie(),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
      errorPolicy: 'all',
    },
  )

  return useMemo(() => {
    return { loading, refetch, startPolling, stopPolling, ...parseGuildRolesAndPermissions(data) }
  }, [data, loading, refetch, startPolling, stopPolling])
}

export function parseGuildRolesAndPermissions(data: UserGuildRolesAndPermissionsQuery | undefined): GuildMember {
  if (!data)
    return {
      email: null,
      guildMemberReason: null,
      roles: [],
      guildPermissions: {
        hasFreeTickets: false,
        hasMerchDiscount: false,
        hasEarlyAccess: false,
        canVote: false,
        adFree: false,
        publicViewingRight: false,
        limitedAds: false,
      },
      hasGuildMembership: false,
      isGuildMember: false,
      isSubscribedToGuild: false,
      isSubscribedToPifOrGuild: false,
    }

  const email = data?.user?.email
  const isGuildMember = Boolean(data?.user?.roles?.map((role) => role?.toLowerCase()).includes('guild_member'))
  const guildMemberReason = data?.user?.guildMemberReason || null
  const roles = data?.user?.roles || []

  const isSubscribedToGuild = isGuildMember && guildMemberReason === GuildMemberReason.GUILD_SUBSCRIBE

  const isSubscribedToPifOrGuild =
    isGuildMember &&
    (guildMemberReason === GuildMemberReason.PIF_SUBSCRIBE || guildMemberReason === GuildMemberReason.GUILD_SUBSCRIBE)
  const hasGuildMembership = isGuildMember && GuildMemberReason.GUILD_SUBSCRIBE === guildMemberReason

  const guildPermissions = mapPermissions(data?.user?.permissions || [])

  return {
    isGuildMember,
    guildMemberReason,
    isSubscribedToGuild,
    isSubscribedToPifOrGuild,
    hasGuildMembership,
    email,
    guildPermissions,
    roles,
  }
}

const mapPermissions = (permissions: (string | null)[]): GuildPermissions => {
  return {
    hasFreeTickets: permissions.includes('ticket_for_every_theatrical_release') || false,
    hasMerchDiscount: permissions.includes('all_merch_discount') || false,
    hasEarlyAccess: permissions.includes('early_access') || false,
    canVote: permissions.includes('guild_vote') || false,
    adFree: permissions.includes('ad_free') || false,
    limitedAds: permissions.includes('limited_ads') || false,
    publicViewingRight: permissions.includes('public_viewing_right') || false,
  }
}

/** @deprecated For permissions and Guild Status.  Get those from useGuildMember instead */
export const useGuildUser = () => {
  const { data, loading, refetch, startPolling, stopPolling } = useQuery<UserGuildMembershipQuery>(
    USER_GUILD_MEMBERSHIP,
    {
      skip: !hasAuthJwtCookie(),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
      errorPolicy: 'all',
    },
  )

  return useMemo(() => {
    return { loading, refetch, startPolling, stopPolling, ...parseGuildUser(data) }
  }, [data, loading, refetch, startPolling, stopPolling])
}

export enum GuildMemberReason {
  PIF_SUBSCRIBE = 'PIF_SUBSCRIBE',
  GUILD_SUBSCRIBE = 'GUILD_SUBSCRIBE',
}

export type GuildUser = {
  isGuildMember: boolean
  guildMemberReason: GuildMemberReason | null
  guildMemberStart: string | null
  guildPlan: GuildPlan | null
  isSubscribedToGuild: boolean
  /**
   * Some perks are given to users based on them paying it forward, such as Early Access or Voting
   */
  isSubscribedToPifOrGuild: boolean
  /**
   * Indicates if the user has an active Guild membership because they are a paying Guild Member
   * At the time of this documentation, this means they are on the $20/month or $175/year plan
   * 20% discount on merch and free tickets to theatrical events are exclusive to this role
   */
  hasGuildMembership: boolean
  email?: string | null
  loading?: boolean
  guildRoles: GuildRoles
}

type UserGuildMembershipQuery = {
  user?: {
    email: string
    guildMemberReason: GuildMemberReason | null
    guildMemberStart: string | null
    guildPlan: GuildPlan | null
    guildRoles: GuildRoles
    isGuildMember: boolean
  }
}

export function parseGuildUser(data: UserGuildMembershipQuery | undefined): GuildUser {
  if (!data)
    return {
      email: null,
      guildMemberReason: null,
      guildMemberStart: null,
      guildPlan: null,
      guildRoles: {},
      hasGuildMembership: false,
      isGuildMember: false,
      isSubscribedToGuild: false,
      isSubscribedToPifOrGuild: false,
    }

  const user = data?.user
  const isGuildMember = !!user?.isGuildMember
  const guildMemberReason = user?.guildMemberReason || null
  const guildMemberStart = user?.guildMemberStart || null
  const guildPlan = user?.guildPlan || null

  const isSubscribedToGuild = isGuildMember && guildMemberReason === GuildMemberReason.GUILD_SUBSCRIBE

  const isSubscribedToPifOrGuild =
    isGuildMember &&
    (guildMemberReason === GuildMemberReason.PIF_SUBSCRIBE || guildMemberReason === GuildMemberReason.GUILD_SUBSCRIBE)
  const hasGuildMembership = isGuildMember && GuildMemberReason.GUILD_SUBSCRIBE === guildMemberReason

  const guildRoles = user?.guildRoles || {}

  return {
    isGuildMember,
    guildMemberReason,
    guildMemberStart,
    guildPlan,
    isSubscribedToGuild,
    isSubscribedToPifOrGuild,
    hasGuildMembership,
    email: user?.email,
    guildRoles,
  }
}

/** @deprecated Get guildRoles from useGuildUser instead */
export const useUserGuildRoles = () => {
  const { data, loading } = useQuery<GuildRolesQuery>(GET_USER_GUILD_ROLES, {
    skip: !hasAuthJwtCookie(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    refetchWritePolicy: 'overwrite',
    errorPolicy: 'all',
  })

  return useMemo(() => {
    return { loading, data }
  }, [data, loading])
}

export function useUserGuildTickets({
  theatricalSlug,
  client,
  skip,
}: {
  theatricalSlug: string
  client: ApolloClient<object>
  skip?: boolean
}): { canClaimTickets: boolean; numberAvailable: number; discountCodes: TheatricalDiscount[]; loading: boolean } {
  const { data: tickets, loading: loadingUserGuild } = useQuery<GetGuildUserTicketsAvailableForSlugQuery>(
    GET_USER_GUILD_TICKETS_AVAILABLE,
    {
      client,
      skip: !hasAuthJwtCookie() || skip,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
      variables: { input: { theatricalSlug } },
      errorPolicy: 'all',
    },
  )

  const { data: discountCodes, loading: loadingDiscountCodes } = useQuery<
    DiscountsListGuildCodesQuery,
    DiscountsListGuildCodesQueryVariables
  >(GET_USER_GUILD_TICKETS_DISCOUNT_CODES, {
    client,
    skip: !hasAuthJwtCookie() || skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    refetchWritePolicy: 'overwrite',
    variables: { input: { theatricalSlug } },
    errorPolicy: 'all',
  })

  return useMemo(() => {
    const canClaimTickets = Boolean(tickets?.numberOfGuildTicketsAvailable?.count)
    const count = tickets?.numberOfGuildTicketsAvailable?.count ?? 0
    const codes = discountCodes?.discountsListGuildCodes?.discounts || []

    return {
      canClaimTickets,
      // staging always returns 1Million tickets to make testing easier
      // so, setting max = 2 if not in prod environment
      numberAvailable: isProductionEnvironment() ? count : Math.min(count, 2),
      discountCodes: codes,
      loading: loadingUserGuild || loadingDiscountCodes,
    }
  }, [tickets?.numberOfGuildTicketsAvailable?.count, loadingUserGuild, discountCodes, loadingDiscountCodes])
}
