import { useCallback, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { paths } from '@/constants'
import { useGuildMember } from '@/services/GuildUserService'
import { useNativeMobileShare, createBasicShareLink } from '@/services/Sharing'
import {
  UserGuildCodesAndProjectNameQuery,
  UserGuildCodesAndProjectNameQueryVariables,
} from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'

export const GET_USER_GUILD_TICKETS_DISCOUNT_CODES_AND_PROJECT_NAME = gql`
  query userGuildCodesAndProjectName($slug: String!) {
    discountsListGuildCodes(input: { theatricalSlug: $slug }) {
      discounts {
        id
        code
        name
        theatricalSlug
      }
    }
    project(slug: $slug) {
      id
      name
    }
  }
`

export function useGuildTickets(theatricalSlug: string) {
  const { isGuildMember, guildPermissions } = useGuildMember()
  const { t } = useTranslate('common')
  const { share } = useNativeMobileShare()

  const { data, loading } = useQuery<UserGuildCodesAndProjectNameQuery, UserGuildCodesAndProjectNameQueryVariables>(
    GET_USER_GUILD_TICKETS_DISCOUNT_CODES_AND_PROJECT_NAME,
    {
      variables: { slug: theatricalSlug },
      skip: !isGuildMember || !theatricalSlug,
      errorPolicy: 'all',
    },
  )

  const projectName = data?.project?.name

  const discountCodes = useMemo(() => {
    if (!guildPermissions?.hasFreeTickets) return []
    return data?.discountsListGuildCodes?.discounts?.map((discount) => discount?.code) ?? []
  }, [data?.discountsListGuildCodes?.discounts, guildPermissions?.hasFreeTickets])

  const giftGuildTicketsMobile = useCallback(async () => {
    if (!projectName || discountCodes.length === 0) return null

    const shareUrl = await createBasicShareLink({
      baseUrl: `${paths.base}/tickets/${theatricalSlug}`,
      params: { flow: 'guild-gift', discounts: discountCodes.join(',') },
    })

    await share({
      text: `${t(
        'youHaveBeenGiftedGuildTicketsToProject',
        "You've Been Gifted Guild Tickets to {{projectName}}. Enjoy the show!",
        {
          projectName,
        },
      )} ${shareUrl}`,
      title: t('youHaveGiftAngelGuildMovieTickets', "You've Been Gifted Angel Guild Movie Tickets"),
      url: shareUrl,
    })
  }, [discountCodes, projectName, share, t, theatricalSlug])

  return useMemo(() => {
    return {
      loading,
      guildDiscountQuantity: discountCodes.length,
      discountCodes,
      areGuildTicketsAvailable: guildPermissions?.hasFreeTickets && discountCodes.length > 0,
      giftGuildTicketsMobile,
    }
  }, [loading, discountCodes, guildPermissions?.hasFreeTickets, giftGuildTicketsMobile])
}
