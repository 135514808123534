import debounce from 'lodash/debounce'

export interface DebounceOptions {
  /**
   * The number of milliseconds to delay before each invocation of the function. Default is 100ms.
   */
  wait?: number

  /**
   * The maximum number of milliseconds to wait before invoking the function. Default is 1000ms.
   */
  maxWait?: number

  /**
   * Whether to invoke the function on the leading edge of the "wait" timeout. Default is false.
   */
  leading?: boolean

  /**
   * Whether to invoke the function on the trailing edge of the "wait" timeout. Default is true.
   */
  trailing?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createDebouncedFunction<T extends (...args: any) => any>(func: T, opts?: DebounceOptions) {
  const { wait, maxWait, leading, trailing } = opts ?? {}
  return debounce(func, wait ?? 100, {
    maxWait: maxWait ?? 1000,
    leading: leading ?? false,
    trailing: trailing ?? true,
  })
}
